<template>
    <section id="Seoicon" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h4>Boost your own beautiful Website</h4>
       </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
              <Image :fpath=" obj.path " class="Softwareimage"/>
              <h4 class="embservice">{{ obj.header }}</h4>
              <p class="embservice">{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'background/Keyword.png',
        header: 'Keyword Planning and Research',
        caption: 'SEO your website through a store of keyword research.'
      },
      {
        path: 'background/Optimizing-Website.png',
        header: 'Optimizing Website',
        caption: 'Optimization of your website requires careful planning to provide an outlook and a proved conversion focussed marketing strategy.'
      },
      {
        path: 'background/Result-Oriented.png',
        header: 'Result- Oriented',
        caption: 'Having a result-oriented approach to every move in marketing is being focussed on the benefits obtained.'
      }
    ]

  })
    
}
</script>
<style>
.services .icon-box img {
    float: left;
    margin-top: 10px;
  }
 
img.Softwareimage {
    width: 15%;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Seoicon {
    padding: 50px 30px 10px 30px;
}
.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
    margin-top: 10px;
}
.embservice{
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>