<template>
    <section id="Seoservice" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>What we Do</h2>
          <p style="padding-top:20px">We work with various factors such as Keywords, valid content, and Backlinks to bring in maximum organic traffic to your online business.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 style="font-size: 15px;">{{ obj.header }}</h4>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/SEO-optimization.jpg',
        header: 'SEO optimization',
        caption: 'We make maximum use of the best resources available online to generate relevant traffic to your site, by letting search engine crawler find your website on the organic search result.'
      },
      {
        path: 'services/Ads.jpg',
        header: 'AdWords Optimization',
        caption: 'We bid on performance Keywords, expecting a high increase in the turn overs of traffic that have made a conversion on your website by visiting the landing page.'
      },
      {
        path: 'services/SEO-Media-Marketing.jpg',
        header: 'Social Media Marketing',
        caption: 'With the dominance of social Medias among today’s crowd, marketing on such site that use new updated content as their search activity. We market to the people in those sites making it viable to reach all in one place.'
      },
      {
        path: 'services/Digital-analysis.jpg',
        header: 'Digital analysis',
        caption: 'With all the planning of spending on a digital marketing a business website, Digital Analytics is a proven platform to showcase opinion versus fact. Knowing the outcomes is an added benefit.'
      },
      {
        path: 'services/Customer-Experience.jpg',
        header: 'Customer Experience',
        caption: 'Customer‘s experience is by far the most important tool for your marketing strategy. With the technological world user experience, transparency in the sales world is considered a plus.'
      },
      {
        path: 'services/Content-Strategy.jpg',
        header: 'Content Marketing',
        caption: 'Any kind of marketing on the web is impossible without a great content. Fresh, updated Content is the only way search crawlers brand your site. We provide effective content marketing gains.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Seoservice {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>