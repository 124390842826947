<template>
    <Header/>
    <Seoheader/>
    <Seoservice/>
    <Seoicons/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Seoservice from '@/components/Seoservice.vue'
import Seoicons from '@/components/Seoicons.vue'
import Seoheader from '@/components/Seoheader.vue'

export default {
  components: {
     Header,
    Footer,
    Seoservice,
    Seoicons,
    Seoheader
  }
}
</script>